import React from 'react';

import blueFamily from '../assets/images/blue-family.jpg';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Header from '../components/Header';
import ReactPlayer from 'react-player'
import process from '../assets/images/Process.png';

 const FamiliesPage= () => (
  <Layout>
    <Header />

    <section id="families" className=" bg-light" style={{ paddingTop: 140 }}>
      <div className="container pb-5">
        <div className="row no-glutters ">
          <div className='col-md-1'></div>
          <div className='col-md-4'>
            <h2> FAMILIES</h2>
            <h4>Mendoza Family</h4>
            <p> Melvia and her Husband, Jorge have lived in  Patulul Suchitepequez, Guatemala their entire lives. </p>
            <p>They have three children: Hector, Elizabeth, and Jonathon. </p>
            <p>The Mendoza family’s dream is to create a better living situation for their family and improve their children's chances of success in life so they can contribute to society. </p>
            <p>hey currently pay rent for their home, and must raise 2,000Q ($260.26) in order to even own the land it sits on.</p>
            <p>With high hopes, they fight daily for this dream despite the odds.</p>
          </div>
          <div className='col-md-1'/>
          <div className='col-md-6 align-self-center'>
            <ResponsivePlayer url = {'https://www.youtube.com/watch?v=ygsL2Zmbujk'} />
          </div>
        </div>

      </div>
    </section>


    <SocialLinks />
    <Footer />
  </Layout>

);

const ResponsivePlayer = ({url}) => {
  return (
    <div style={{ position: 'relative', paddingTop: '52.8%' }}>
      <ReactPlayer
        style={{ position: 'absolute', top: '0', left: '0' }}
        url= {url}
        light={false}
        width='100%'
        height='100%'
      />
    </div>
  )
}

export default FamiliesPage;